.app-header {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    width: 100%;
    padding: 16px 24px;
    z-index: 1000;
    /* Adjust the z-index as needed to ensure it's above other elements */
    /* position: fixed; */
    justify-content: space-between;
}

.heCXBQ {
    display: flex;
}

.kAboyZ {
    background: none;
    margin-right: 20px;
    border: none;
    color: white;
}

.eBRzvd {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    -webkit-letter-spacing: -0.010959778564167367em;
    -moz-letter-spacing: -0.010959778564167367em;
    -ms-letter-spacing: -0.010959778564167367em;
    letter-spacing: -0.010959778564167367em;
    line-height: 28px;
}