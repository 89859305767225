@media (min-width: 1366px) {
    .erPtxw {
        padding-top: 0px;
    }
}

@media (min-width: 1366px) {
    .erPtxw {
        flex-flow: row;
    }
}

.erPtxw {
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    min-height: calc(100vh - 120px);
}


@media (min-width: 1366px) {
    .klBxhW {
        padding-bottom: 80px;
    }
}

.klBxhW {
    align-items: flex-start;
    display: flex;
    padding: 0px 24px 88px;
    width: 100%;
}


.cKVRhv {
    display: flex;
    flex-flow: wrap;
    margin: 0px auto;
    padding-bottom: 24px;
    width: 100%;
    max-width: 424px;
    justify-content: center;

}


.cUiCiF {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
}


.kWqtoM {
    color: white;
    display: flex;
    font-size: 18px;
    line-height: 26px;
}

.czwczU {
    margin: 8px;
}

.jcRkmu {
    min-height: 40px;
    padding: 4px 10px;
    background-color: black;
}

.kHAZvO {
    -webkit-box-align: center;
    align-items: center;
    border: 2px solid #12B2B3;
    display: inline-flex;
    background-color: #2A2A2A;
    cursor: pointer;
}

.hjicdk3 {
    -webkit-box-align: center;
    align-items: center;
    border: 2px solid transparent;
    display: inline-flex;
    background-color: #2A2A2A;
    cursor: pointer;
}

.ieunKf {
    display: flex;
    flex-flow: column;
    opacity: 1;
    width: 100%;
}

.efFBMo {
    color: white;
    display: flex;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 3px;
}

.dlDjfT {
    display: flex;
    flex-flow: column;
    position: relative;
    width: 100%;
}

.hzkkDc {
    color: #757575;
    font-size: 18px;
    line-height: 1.5;
    left: 12px;
    position: absolute;
    top: 15px;
    cursor: text;
    pointer-events: none;
}

.fPLSVc {
    background-color: #2A2A2A;
    border: 1px solid #12B2B3;
    font-size: 18px;
    line-height: 1.5;
    padding: 0px 11px;
    height: 56px;
    border-radius: 1px;
    color: white;
}

.fPLSVc:focus {
    background-color: #2A2A2A;
    border: 1px solid #12B2B3;
}

.fPLSVc::-moz-placeholder {
    background-color: #2A2A2A;
    border: 1px solid #12B2B3;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background-color: black !important;
    background: black;
}

/* Placeholder style for the selected state */
input:-webkit-autofill::placeholder {
    color: #333;
    /* Change to the desired color */
}


input,
button,
select,
optgroup,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    outline: none;
}