.dwlkSV {
    margin-bottom: 16px;
    width: 100%;
}

.hhbXPR {
    overflow: hidden;
    padding: 28px 22px;
    height: 168px;
}

.hjicdk12 {
    -webkit-box-align: center;
    align-items: center;
    display: inline-flex;
    background-color: white;
    cursor: pointer;
    max-width: 424px;
    background-color: #1e1e1e;
    padding-left: 10px;
}

.hjicdk12.isChecked {
    border: 2px solid #12B2B3;
    /* Set the border width and color */
}

.hjicdk12:hover {
    background-color: #2c2c2c;
}


.jGXvKN {
    align-self: center;
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    flex: 1 1 0%;
    flex-flow: column;
    padding-right: 16px;
    width: 100%;
}

.kSpLAz {
    margin-left: auto;
    margin-top: -16px;
    margin-right: -16px;
}


.ghbokT1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.0183197em;
    line-height: 30px;
    color: white;
    text-align: left;
    margin-left: 10px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}


/* Add these styles to customize the checkbox */
.checkbox-title {
    display: flex;
    align-items: center;
}

.checkbox-input {
    margin-right: 8px;
    /* Adjust the spacing between the checkbox and the title */
}

.getStepsData {
    inset: 0px;
    padding: 0px;
    border: none;
    margin: auto;
    object-fit: contain;
    -webkit-box-align: center;
    border: 2px solid transparent;
    display: inline-flex;
    cursor: pointer;
    width: 180px;
    height: 250px;
}