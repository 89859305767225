.howdoesitwork-container {
    /* padding: 24px 48px 32px; */
    width: calc(100% - 60px);
    font-family: Arial, sans-serif;
    padding-top: 30px;
    margin-bottom: 80px;
  }


@media (min-width: 1366px){
    .howdoesitwork-container {
        max-width: 1126px;
        width: calc(100% - 240px);
    }
}

  .howdoesitwork-title {
    text-align: center;
    font-weight: 900;
    font-family: Arial, sans-serif;
    font-size: 40px;
    color: white;
    margin: 0px 0px 30px 0px;
  }
  
  .howdoesitwork-intro {
    text-align: center;
    text-align: center;
    margin-bottom: 60px;
    font-size: 24px;
    font-weight: 400;
    font-family: Arial, sans-serif;
    color: rgb(205, 205, 205);

  }
  
  .howdoesitwork-grid {
    display: grid;
    margin-top: 70px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .howdoesitwork-item {
    cursor: pointer;
  }
  
  .howdoesitwork-question {
    font-size: 18px;
    color: white;
    display: flex;
    align-items: center;
    margin: 0;
    text-align: start;
  }

  
  .howdoesitwork-answer {
    padding: 0px 0;
    color: rgb(213, 213, 213);
    text-align: start;
  }
  
  /* Font Awesome Icons */
  .howdoesitwork-icon.fa {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }
  
  @media (max-width: 600px) {
    .howdoesitwork-grid {
      display: block;
    }

  }

  .howdoesitwork-item {
    margin-bottom: 10px;
    color: white;
    text-align: start;
    border: 1px solid transparent; /* Remove the bottom white line */
  }

  .faq-image {
    width: 50px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 20px; /* Add spacing between image and text */
    float: left; /* Float the image to the left */
    
  }
  