/* CheckoutForm.css */
#checkout-form{
    width: 100%;
}

.checkout-form-header{
    display: flex;
    text-align: start;
    align-items: start;
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #12B2B3; 
    border-radius: 5px;/* Border with width and color */
}

.payment-form {
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
}

#payment-element {
    border-radius: 4px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.pay-now-button {
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 40px;
    border: none;
    border-radius: 5px;
    background-color: #12B2B3;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.pay-now-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.1); /* Change border color to white with opacity */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: auto;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.payment-message {
    background: red;
    margin-top: 20px;
    color: white;
    font-size: 12px;
    padding: 10px;
    text-align: left;
    border-radius: 5px; /* Adding rounded corners */
}
