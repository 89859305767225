.aSuiop {
    color: white;
    width: 100%;
    padding: 0 24px;

  }

@media (min-width: 768px) {
    .aSuiop {
        max-width: 476px;   
     }
}

@media (min-width: 1366px) {
    .aSuiop {
        max-width: 1126px;
    }
}
  
  p {
    font-size: 18px;
    text-align: justify;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  b {
    font-weight: bold;
  }