
/* CSS for cookie policy container */
.cookie-policy-container {
    position: relative;
    overflow: hidden;
    background: radial-gradient(60.6% 40.78% at 49.85% 44.22%, rgba(31, 147, 255, 0.275) 0%, rgba(30, 30, 30, 0) 100%) black;

  }
  /* CSS for content */
  .content {
    display: flex;
    flex-direction: column;
    padding-top: 50px; /* Adjust as needed based on the header height */
    overflow-y: auto;
    align-items: center;
    color: white;
  }

.kHLZZt {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;

}

.jkzPLI {
    display: flex;
    flex-direction: column;
    color: white;
    padding: 20px 16px;
    width: calc(100% - 60px);

}
/* 
@media (min-width: 360px) {
    .jkzPLI {
        max-width: 360px;   
     }
}

@media (min-width: 768px) {
    .jkzPLI {
        max-width: 476px;   
     }
} */

@media (min-width: 1366px) {
    .jkzPLI {
        max-width: 1126px;
    }
}

.jkzPLI a {
    font-size: 18px;
    line-height: 26px;
    color: #12B2B3;
}

.jkzPLI li {
    padding: 12px 0px;
    text-align: start;
}

.table-wrapper {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
}

td p {
    margin: 0;
    padding: 0;
}

a {
    color: #12B2B3;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}