.dDZAwI {
    margin: 8px;
}

.hPRORS {
    min-height: 40px;
    padding: 4px 10px;
}

.blJLHD {
    -webkit-box-align: center;
    align-items: center;
    display: inline-flex;
    background-color: #272626;
    cursor: default;
}

.eBRzvd {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.0109598em;
    line-height: 28px;
}