@media (min-width: 768px) {
    .loCkhE {
        margin-top: 0px;
        flex-flow: wrap;

    }
}

.loCkhE {
    display: flex;
    flex-flow: row;
    min-height: 440px;
}

.ePONvi {
    margin-top: 50px;
}

.jYMLVW {
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 247px;
}

@media (min-width: 1366px) {
    .jYMLVW {
        -webkit-box-align: center;
        align-items: center;
        min-height: 100vh;
        height: auto;
        width: 50%;
    }
}


@media (max-height: 600px) {
    .jYMLVW {
        min-height: 300px;
    }
}

@media (min-width: 1366px) {
    .kiSsKQ {
        padding-top: 70px;
    }
}

@media (min-width: 1024px) {
    .kiSsKQ {
        background-position: center -20px;
        padding: 96px 0px 70px;
    }
}

@media (min-width: 768px) {
    .kiSsKQ {
        background-position: center -11px;
        padding: 96px 0px 60px;
    }
}

.kiSsKQ {
    padding: 96px 0px 45px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
}


.fQWqWj {
    display: flex;
    flex-flow: row;
    min-height: 452px;
    background-color: black;
    height: 100%;
}

/* Media Query for small screens */
@media (max-width: 767px) {
    .fQWqWj {
        flex-flow: column;
    }
}

/* Media Query for medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
    .fQWqWj {
        flex-flow: column;
    }
}

/* Media Query for large screens */
@media (min-width: 1024px) and (max-width: 1365px) {
    .fQWqWj {
        flex-flow: row;
    }
}

/* Media Query for extra-large screens */
@media (min-width: 1366px) {
    .fQWqWj {
        flex-flow: row;
    }
}


@media (min-width: 1366px) {
    .jlxXQZ {
        -webkit-box-align: center;
        align-items: center;
        min-height: 100vh;
        height: auto;
        width: 50%;
    }
}


@media (min-width: 768px) {
    .jlxXQZ {
        min-height: 300px;
        width: 100%;
    }
}

.jlxXQZ {
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    overflow: hidden;
    height: 226px;
    width: 50%;
}


.grid-bg {
    background: linear-gradient(to left, #2A2A2A, 1px, transparent 1px), linear-gradient(0deg, #2A2A2A, 1px, transparent 1px);
    background-color: black;
    background-size: 44px 44px;
    height: 100%;
    width: 100%;
    align-items: center;
}


@media (min-width: 768px) {
    .grid-bg {
        background-size: 79px 79px;
    }
}


@media (min-width: 1366px) {
    .grid-bg {
        background-size: 84px 84px;
        width: 50%;
    }
}



.uxnsv {
    padding: 96px 0px 45px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

@media (min-width: 1366px) {
    .uxnsv {
        padding-top: 0px;
    }
}

@media (min-width: 1024px) {
    .uxnsv {
        padding: 0px;
    }
}

@media (min-width: 768px) {
    .uxnsv {
        background-position: center -11px;
        padding: 0px;
    }
}


.fOdZdy {
    align-items: center;
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 110px;
    width: 100%;
    background: #1E1E1E;
    justify-content: center;
}

@media (min-width: 1366px) {
    .fOdZdy {
        -webkit-box-align: center;
        align-items: center;
        height: auto;
        margin-left: auto;
        padding: 96px 64px 64px 80px;
        width: 50%;
    }
}

@media (max-width: 768px) {
    .fOdZdy {
        padding-top: 24px;
        margin-bottom: -140px;
    }
}




.ltAyV {
    display: flex;
    flex-flow: wrap;
    margin: auto;
    padding: 16px 0px;
    width: 100%;
    -webkit-box-pack: start;
    justify-content: flex-start;
    max-width: 500px;
    justify-content: center;
}


@media (min-width: 1366px) {
    .ltAyV {
        -webkit-box-pack: center;
        justify-content: center;
        max-width: 470px;
        margin: 0px;
    }
}


.gppALw {
    width: 100%;
    display: flex;
    flex-flow: column;
    background-color: black;
    max-width: 424px;
    width: 100%;
}

@media (min-width: 768px) {
    .gppALw {
        margin-bottom: 20px;
    }
}



.osnNu {
    display: flex;
    flex-flow: column;
    width: 100%;
    text-align: left;
    margin-bottom: 30px;
}

@media (min-width: 1366px) {
    .bpzQjr {
        font-size: 34px;
        line-height: 48px;
    }
}

.bpzQjr {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    letter-spacing: -0.020903em;
    line-height: 40px;
    color: white;
}

.bUfyfo {
    display: flex;
    flex-direction: column;
    position: relative;
    background: transparent;
    width: 100%;
    padding: 0px 0px;
}

.dAIBsK {
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 8px;
    background-color: transparent;
    background-position: left center, right center;
    background-repeat: no-repeat;
}

.ehFIUl {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 6px;
}

.dezAzo {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.00622354em;
    line-height: 22px;
    color: white;
}

.cDpolK0 {
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.0143007em;
    line-height: 26px;
    width: 100%;

}

.bsCFUQ {
    margin-left: 0px;
}



.bIrthQ {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
    left: 14px;
    right: 14px;
    top: 10px;
    color: white;
    align-self: center;
    max-width: 424px;
    width: 100%;
}

.csZvvW {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background-color: transparent;
    background-image: linear-gradient(270deg,
    #12B2B3 0%, #12B2B3 9.50%,
            /* Block 1 */
            transparent 9.50%, transparent 10%,
            /* Gap 1 */
            #11a1a1 10%, #11a1a1 19.5%,
            /* Block 2 */
            transparent 19.5%, transparent 20%,
            /* Gap 2 */
            #119494 20%, #119494 29.5%,
            /* Block 3 */
            transparent 29.5%, transparent 30%,
            /* Gap 3 */
            #0b6e6e 30%, #0b6e6e 39.5%,
            /* Block 4 */
            transparent 39.5%, transparent 40%,
            /* Gap 4 */
            #095b5b 40%, #095b5b 49.5%,
            /* Block 5 */
            transparent 49.5%, transparent 50%,
            /* Gap 5 */
            #074c4c 50%, #074c4c 59.5%,
            /* Block 6 */
            transparent 59.5%, transparent 60%,
            /* Gap 6 */
            #053d3d 60%, #053d3d 69.5%,
            /* Block 7 */
            transparent 69.5%, transparent 70%,
            /* Gap 7 */
            #043333 70%, #043333 79.5%,
            /* Block 8 */
            transparent 79.5%, transparent 80%,
            /* Gap 8 */
            #032b2b 80%, #032b2b 89.5%,
            /* Block 9 */
            transparent 89.5%, transparent 90%,
            /* Gap 9 */
            #022222 90%, #022222 100%
            /* Block 10 */
        );
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s, background-image 0.2s;
}

.csZvvW0 {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background-color: transparent;
    background-image: linear-gradient(270deg,
    #12B2B3 0%, #12B2B3 14.166%,
            /* Block 1 */
            transparent 14.166%, transparent 14.666%,
            /* Gap 1 */
            #12B2B3 14.666%, #12B2B3 28.832%,
            /* Block 2 */
            transparent 28.832%, transparent 29.332%,
            /* Gap 2 */
            #12B2B3 29.332%, #12B2B3 43.498%,
            /* Block 3 */
            transparent 43.498%, transparent 43.998%,
            /* Gap 3 */
            #12B2B3 43.998%, #12B2B3 58.164%,
            /* Block 4 */
            transparent 58.164%, transparent 58.664%,
            /* Gap 4 */
            #12B2B3 58.664%, #12B2B3 72.83%,
            /* Block 5 */
            transparent 72.83%, transparent 73.33%,
            /* Gap 5 */
            #12B2B3 73.33%, #12B2B3 87.496%,
            /* Block 6 */
            transparent 87.496%, transparent 87.996%,
            /* Gap 6 */
            #12B2B3 87.996%, #12B2B3 100%
            /* Additional Block or Gap */
        );
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.csZvvW::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #12B2B3;
    /* thumb color */
    border-radius: 50%;
    /* make the thumb circular */
    cursor: pointer;
}

.csZvvW::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #12B2B3;
    /* thumb color */
    border-radius: 50%;
    /* make the thumb circular */
    cursor: pointer;
}


.csZvvW0 {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background-color: transparent;
    background-image: linear-gradient(270deg,
    #12B2B3 0%, #12B2B3 14.285%,
            /* Block 1 */
            transparent 14.285%, transparent 14.785%,
            /* Gap 1 */
            #12B2B3 14.785%, #12B2B3 29.07%,
            /* Block 2 */
            transparent 29.07%, transparent 29.57%,
            /* Gap 2 */
            #12B2B3 29.57%, #12B2B3 43.855%,
            /* Block 3 */
            transparent 43.855%, transparent 44.355%,
            /* Gap 3 */
            #12B2B3 44.355%, #12B2B3 58.64%,
            /* Block 4 */
            transparent 58.64%, transparent 59.14%,
            /* Gap 4 */
            #12B2B3 59.14%, #12B2B3 69.425%,
            /* Block 5 */
            transparent 69.425%, transparent 69.925%,
            /* Gap 5 */
            #12B2B3 69.925%, #12B2B3 84.21%,
            /* Block 6 */
            transparent 84.21%, transparent 84.71%,
            /* Gap 6 */
            #12B2B3 84.71%, #12B2B3 100%
            /* Additional Block or Gap */
        );
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}


.csZvvW0::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #12B2B3;
    /* thumb color */
    border-radius: 50%;
    /* make the thumb circular */
    cursor: pointer;
}

.csZvvW0::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #12B2B3;
    /* thumb color */
    border-radius: 50%;
    /* make the thumb circular */
    cursor: pointer;
}


.jwNpUC {
    background: rgb(28, 28, 28);
    padding: 0px 12px;
    margin-top: 16px;
    align-self: center;
    text-align: start;
    max-width: 424px;
    width: 100%;
}

.eqMVoN {
    margin-bottom: 4px;
}

.ecFHhf {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -0.0143007em;
    line-height: 26px;
    color: white;
}

.eMcVWt {
    background: url('../images/bg.svg') center 2px / contain no-repeat;
    padding: 96px 0px 45px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
}

@media (min-width: 1366px) {
    .eMcVWt {
        padding-top: 70px;
    }
}


@media (min-width: 1024px) {
    .eMcVWt {
        background-position: center -20px;
        padding: 96px 0px 70px;
    }
}

@media (min-width: 768px) {
    .eMcVWt {
        background-position: center -11px;
        padding: 96px 0px 60px;
    }
}