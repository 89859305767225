.klVzKw {
    inset: 0px;
    z-index: 1;
    width: 375px;
    overscroll-behavior: none;

}

.button {
    border-radius: 0;
    background: none;
    border: none;
    outline: none;
    padding: 0;
}

.hSXcLQ {
    background: #2a2a2a;
    align-items: center;
    max-width: 375px;
    padding: 12px 24px;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100%;
    z-index: 3;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
}


.ieDQDU {
    background: #2a2a2a;
    inset: 0px;
    opacity: 0.7;
    position: absolute;
    z-index: 1;
}

.dbjdqK {
    background: #2a2a2a;
    display: flex;
    flex-direction: column;
    padding: 0px 24px;
    position: absolute;
    top: 30px;
    bottom: -20px;
    right: 0px;
    width: 100%;
    max-width: 375px;
    z-index: 2;
    list-style-type: none;
}

.gRyEac {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #313131;
    /* You can adjust the width and color */

}

.fQvCds {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #313131;
    /* You can adjust the width and color */

}

.grKXJU {
    border-bottom: 2px solid #313131;
    /* You can adjust the width and color */
}



button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
    cursor: pointer;
    border: none;
}

.gRyEac a,
.gRyEac button {
    padding: 16px 24px 16px 40px;
    text-align: left;
    margin: 0px -24px;
    width: calc(100% + 48px);
    display: -webkit-box; /* Add -webkit prefix */
    display: flex;
    -webkit-box-pack: justify; /* Add -webkit prefix */
    justify-content: space-between;
}

.fQvCds a,
.fQvCds button {
    -webkit-box-pack: justify;
    justify-content: space-between;
    display: -webkit-box; /* Add -webkit prefix */
    display: flex;
    padding: 16px 24px 16px 40px;
    text-align: left;
    margin: 0px -24px;
    width: calc(100% + 48px);
    
    font-size: 18px;
    line-height: 26px;
}


.grKXJU a,
.grKXJU button {
    padding: 16px 24px 16px 40px;
    text-align: left;
    margin: 0px -24px;
    width: calc(100% + 48px);
    display: flex;
    display: -webkit-box; /* Add -webkit prefix */
    display: flex;
    -webkit-box-pack: justify; 
    justify-content: space-between;
}

a {
    text-decoration: none;
    background-color: transparent;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
    cursor: pointer;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
    cursor: pointer;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
    cursor: pointer;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
    cursor: pointer;
}

ul {
    list-style-type: disc;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.iQMCza {
    height: 24px;
    min-width: 24px;
    width: 24px;
}