.kgPnpb {
    background-color: rgba(18, 18, 18, 0.9);
    padding: 24px;
    z-index: 4;
}

.cCVhFd .ModalInner {
    background: #2A2A2A;
    max-width: 422px;
    padding: 0px;
}

@media (min-width: 768px) {
    .hFIkTS {
        padding: 40px;
        max-width: 570px;
        width: 100%;
    }
}

.hFIkTS {
    position: relative;
    background-color: var(--color-black60);
    padding: 16px;
    max-width: 470px;
    width: 100%;
}

.dwUDVU {
    position: relative;
    z-index: 0;
}


.kyAyiJ {
    position: relative;
    z-index: 3;
    padding-bottom: 16px;
    margin: -60px 65px 0px 46px;
    text-align: start;
}

.lnihYb {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 8px;
}

.ioepIY {
    font-size: 18px;
    font-weight: 700;
    font-size: 30px;
    letter-spacing: -0.0143007em;
    line-height: 26px;
    text-transform: none;
    color: white;
}


.jXaydE {
    color: white;
    padding: 8px 0px 32px;
    color: white;
}

.lhlHag {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.00622354em;
    line-height: 22px;
    text-transform: none;
}


.lbleke {
    width: 100%;
}

.fdiIEf {
    display: inline-flex;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 0 16px;
    -webkit-box-align: center;
    text-align: center;
}

.eTNYAs {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    padding: 16px;
    background: transparent;
    color: white;
    width: 100%;
    border: none;
}

.fKEBTI {
    color: white;
}

.kbMuAn {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.0143007em;
    line-height: 24px;
    color: white;
    text-align: center;
}