.circular-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: black; /* Match background color as needed */
}

.circular-progress-svg {
    width: 50px;
    height: 50px;
    animation: rotate 2s linear infinite;
}

.circular-progress-circle {
    fill: none;
    stroke: #12B2B3; /* Change color as needed */
    stroke-width: 4;
    stroke-dasharray: 89, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}
.circular-progress-image-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    overflow: hidden;
}

.circular-progress-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
