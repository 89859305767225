.jCSgRm {
    margin-bottom: 24px;
}

.cqjfzM {
    -webkit-box-align: center;
    align-items: center;
    background-color: #1E1E1E;
    display: flex;
    padding: 8px 16px;
    min-height: 64px;
    width: 100%;
}

.ijvsZU {
    display: inline-flex;
    font-size: 28px;
    line-height: 1;
    margin-right: 16px;
}

.eABpMJ {
    max-width: 424px;
    width: 100%;
}

.cJQjQb{
    font-size: 12px;
    line-height: 1;
    margin-top: 20px;
    display: flex;
    align-items: center;
}
.cDpolK{
    width: 400px;
}

.ceRtyP{
    font-size: 16px;
    flood-color: white;
}
.ouItrP{
    width: 50px;
    text-align: center;
}