@media (min-width: 1366px) {
    .dBqGlA {
        margin-top: 0px;
        padding-bottom: 80px;
        padding-top: 104px;
    }
}

.dBqGlA {
    margin-top: -72px;
    padding: 96px 24px 0px;
}

.gxSiS {
    max-height: 620px;
}

@media (max-width: 768px) {
    .gxSiS {
        max-height: 620px;
        overflow: hidden;
        width: 100%;
    }
}

@media (min-width: 1366px) {
    .gxSiS {
        margin: 0px;
        max-height: 620px;
        position: absolute;
        right: -40px;
        top: -44px;
        width: 350px;
    }
}

/* Media Query for small screens */
@media (max-width: 767px) {
    .jxBQkj {
        text-align: center;
        justify-content: center;

    }
}

/* Media Query for medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
    .jxBQkj {
        padding: 32px;
        text-align: center;
        justify-content: center;
    }
}


/* Media Query for large screens */
@media (min-width: 1024px) and (max-width: 1365px) {
    .jxBQkj {
        padding: 32px;
        text-align: center;
        justify-content: center;
    }
}


@media (min-width: 1366px) {
    .jxBQkj {
        align-content: flex-end;
        padding-right: 344px;
        margin: 0px auto;
        text-align: left;
        min-height: 548px;
        width: 100%;
        max-width: 760px;
    }
}

.jxBQkj {
    display: flex;
    flex-flow: wrap;
    position: relative;
    width: 100%;
}



@media (min-width: 1366px) {
    .jEFptg {
        margin-bottom: 40px;
    }
}

.jEFptg {
    margin-bottom: 24px;
    width: 100%;
}

.cDpolK1 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: -0.0143007em;
    line-height: 26px;
    color: white;
}


/* Media Query for small screens */
@media (max-width: 767px) {
    .eOPdCo>p {
        padding-right: 0px;
    }
}

.eOPdCo {
    display: inline-flex;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 0 16px;
    -webkit-box-align: center;
    align-items: center;
    background: #12B2B3;
    max-width: 370px;
    width: 100%;
}

.eOPdCo>p {
    width: 100%;
    text-align: left;
}



.kbMuAn {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.0143007em;
    line-height: 24px;
    color: white;
}




/* Media Query for small screens */
@media (max-width: 767px) {
    .ceGSfP {
        padding: 32px;

    }
}

/* Media Query for medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
    .ceGSfP {
        padding: 32px;
    }
}

/* Media Query for large screens */
@media (min-width: 1024px) and (max-width: 1365px) {
    .ceGSfP {
        padding: 32px;

    }
}

/* Media Query for extra-large screens */
@media (min-width: 1366px) {
    .ceGSfP {
        -webkit-box-pack: start;
        display: flex;
        padding: 106px 0px 0px 0px;
    }
}


.ceGSfP {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    width: 100%;
}

@media (min-width: 1366px) {
    .gDLWVz {
        margin: 0px;
    }
}

.gDLWVz {
    display: block;
    width: 100%;
    margin: auto;
}

@media (min-width: 1366px) {
    .lgDkiv {
        margin: 0px;
        height: 620px;
    }
}

.lgDkiv {
    margin: auto;
    width: 100%;
}



.imgQrT {
    box-sizing: border-box;
    padding: 0;
    border: none, ;
    margin: auto;
    display: block;
    width: 350px;
    height: 619px
}


/* Media Query for small screens */
@media (max-width: 767px) {
    .imgQrT {
        padding: 32px 0px;
        width: 100%;
        max-width: 350px;
    }
}

/* Media Query for medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
    .imgQrT {
        padding: 32px 0px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

/* Media Query for large screens */
@media (min-width: 1024px) and (max-width: 1365px) {
    .imgQrT {
        padding: 32px 0px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

/* Media Query for extra-large screens */
@media (min-width: 1366px) {
    .imgQrT {
        top: 10;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        align-items: self-start;
    }
}


.imgPaStore {
    margin-right: 20px;
}