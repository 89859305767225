.dLgqLh {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    margin-bottom: 16px;
    width: 100%;
    text-align: start;
}

.hmGzV {
    padding-right: 8px;
    word-break: break-word;
    width: 100%;
    color: white;
}

.dLgqLh {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    margin-bottom: 16px;
    width: 100%;
}

.dcMtwF {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -0.0143007em;
    line-height: 26px;
    color: white;
}

@media (min-width: 1366px) {
    .dcMtwF {
        font-size: 22px;
        line-height: 30px;
    }
}





.cYbNRy {
    border: 2px solid #ffffff;
    padding: 16px;
    text-align: start;
    border: 2px solid #393939;
    max-width: 424px;
    width: 100%;
}

.dLgqLh {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    margin-bottom: 16px;
    width: 100%;
}

.kUUxCL {
    margin-bottom: 16px;
}

.dezAzo {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.00622354em;
    line-height: 22px;
}

.eCjhXJ {
    margin-bottom: 4px;
}