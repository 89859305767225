@media (min-width: 1366px) {
    .iopLoU {
        padding-bottom: 80px;
    }
}

.iopLoU {
    align-items: flex-start;
    display: flex;
    padding: 24px 24px;
    width: 100%;
    justify-content: center;
}


@media (min-width: 1366px) {
    .bpzQjr {
        font-size: 34px;
        line-height: 48px;
    }
}

.bpzQjr {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    letter-spacing: -0.020903em;
    line-height: 40px;
    color: var(--color-black0);
}


@media (min-width: 1366px) {
    .eGxKKN {
        margin: 0px 0px;
    }
}


@media (min-width: 768px) {
    .eGxKKN {
        font-size: initial;
    }
}

.eGxKKN {
    margin: 0px 0px;
}

.dezAzo {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.00622354em;
    line-height: 22px;
    color: white;
}


@media (min-width: 1366px) {
    .bpzQjr {
        font-size: 34px;
    }
}

.bpzQjr {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    letter-spacing: -0.020903em;
    line-height: 40px;
    color: white;
}



.liJdVi {
    display: flex;
    flex-flow: column;
    align-items: center;
    align-self: center;
    margin-bottom: 0px;
    max-width: 424px;
    width: 100%;
    padding-bottom: 30px;
}

.liJdVi1 {
    display: flex;
    flex-flow: column;
    align-items: center;
    align-self: center;
    margin-bottom: 0px;
    max-width: 424px;
    width: 100%;
}

.container-arrow {
    max-width: 424px;
    text-align: end;
    margin-bottom: 50px;
    width: 100%;
}