.gINUjI{
    background: radial-gradient(60.6% 40.78% at 49.85% 44.22%, rgba(31, 147, 255, 0.275) 0%, rgba(30, 30, 30, 0) 100%) black;
}

.byJmIv {
    color: white;
    
}
.iKRgKP {
    padding: 4px 24px 0px;
    background: white;
}

.jHYaJm {
    display: inline-flex;
    gap: 9px;
    align-items: center;
    justify-content: center;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button,
select {
    text-transform: none;
}

button,
input {
    overflow: visible;
}

input,
button,
select,
optgroup,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    outline: none;
}

button {
    border-radius: 0;
    background: none;
    border: none;
    outline: none;
    padding: 0;
}

.ViRJD {
    background: radial-gradient(60.24% 25.63% at 53.6% 29.37%, rgba(255, 68, 0, 0.35) 0%, rgba(255, 68, 0, 0) 100%) ;
    margin-top: -65px;
    padding: 90px 0px 0px;
    background-color: white;
}

.eWIOmr {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0px 24px;
}

.cHvfeM {
    max-width: 330px;
    margin-bottom: -6px;
    overflow: hidden;
}

.byJmIv {
    background: var(--color-black80);
    box-shadow: rgb(57, 57, 57) 0px 1px 0px inset;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

section {
    display: block;
    unicode-bidi: isolate;
}

.jWvJVz {
    text-align: center;
    padding: 32px 24px;
    max-width: 768px;
    margin: 0px auto;
}

.dNPhAg {
    padding-bottom: 8px;
    font-size: 34px;
}

@media (min-width: 768px) {
    .dNPhAg {
        font-size: 48px;
        line-height: 1.4;
    }
}

.hcLSri {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.020903em;
    line-height: 40px;
    text-transform: none;
}

.kPzTO {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.0143007em;
    line-height: 26px;
    text-transform: none;
    color: var(--color-black0);
}

.hDyKSx {
    max-width: 312px;
    margin: 0px auto;
}

.gupRYI {
    display: inline-flex;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 16px;
    align-items: center;
    background: var(--color-primary50);
    width: 100%;
}


.bZHRdM {
    padding: 40px 0px 10px;
    text-align: center;
}

.djCURJ {
    margin-bottom: 16px;
    padding: 0px 24px;
}

@media (min-width: 768px) {
    .djCURJ {
        font-size: 34px;
    }
}

.hcLSri {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.020903em;
    line-height: 40px;
    text-transform: none;
}


.gkcIan {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    margin: 60px 0px 13px;
}

.nkAzd {
    margin-top: 40px;
    padding: 40px 32px 64px;
}

@media (min-width: 768px) {
    .nkAzd {
        padding: 80px 32px;
        background: radial-gradient(33.59% 43.93% at 50.13% 50%, rgba(255, 68, 0, 0.4) 0%, rgba(255, 68, 0, 0.063) 64.58%, rgba(255, 68, 0, 0) 100%), rgb(18, 18, 18);
    }
}


.gWYEah {
    max-width: 375px;
    margin: 0px auto;
}

.OXTho {
    font-size: 34px;
    line-height: 48px;
    margin-bottom: 24px;
    text-align: center;
    color: white;
}

.OdQFA {
    display: block;
    margin: 0px auto;
    max-width: 312px;
    width: 100%;
}

.gupRYI {
    display: inline-flex;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 16px;
    align-items: center;
    background: var(--color-primary50);
    width: 100%;
}


.gupRYI>p {
    padding-right: 32px;
}

.gupRYI>p {
    width: 100%;
    text-align: left;
}

.fpWLlX {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.0143007em;
    line-height: 24px;
    text-transform: none;
    color: var(--color-black1);
}


.fEisXp {
    background-color: var(--color-black80);
    padding: 24px;
    text-align: center;
}

@media (min-width: 768px) {
    .fEisXp {
        padding: 24px 24px 40px;
    }
}



@media (min-width: 768px) {
    .immPOy {
        display: flex;
        width: 100%;
      
    }
}


.immPOy {
    justify-content: center;
    list-style: none;
    display: flex;
    flex-direction: row;
}


@media (min-width: 768px) {
    .cvVVtt:not(:last-child) {
        margin-bottom: 0px;
    }
}

.cvVVtt:not(:last-child) {
    margin-bottom: 16px;
}

@media (min-width: 768px) {
    .cvVVtt {
        margin: 0px 20px;
    }
}

@media (min-width: 1366px) {
    .gDLWVz1 {
        margin: 0px;
    }
}

.gDLWV1 {
    display: block;
    width: 150px;
    margin: auto;
}