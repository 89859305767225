@media (min-width: 768px) {
    .dPbQIl {
        padding-top: 72px;
    }
}

.dPbQIl {

    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding-top: 48px;
    width: 100%;
}

@media (min-width: 768px) {
    .hVZVwo {
        width: 608px;
        margin: 0px auto;
    }
}

.hVZVwo {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
}

@media (min-width: 768px) {
    .gSdIbF {
        margin-bottom: 80px;
    }

}

.gSdIbF {
    margin-bottom: 32px;
    width: 100%;
}

.jrfHvj {
    background: url('../images/arrow.png') center center no-repeat;
    display: flex;
    max-height: 265px;
    position: relative;
    width: 100%;
    z-index: -1;
}

.eJQloD {
    overflow: hidden;
    margin-top: 16px;
    text-align: center;
    width: 50%;
    display: flex;
    justify-content: center;
}


.fXVnti {
    display: flex;
    background: #121212;
}


.bGoIGZ:not(:last-child) {
    border-right: 2px solid #1E1E1E;
}

@media (min-width: 768px) {
    .bGoIGZ {
        -webkit-box-pack: center;
        justify-content: center;
    }
}

.bGoIGZ {
    display: flex;
    width: 50%;
    padding: 8px 24px;
}

.ecFHhf {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -0.0143007em;
    line-height: 26px;
    color: white;
}

.OJDXK {
    display: flex;
    position: relative;
    border-top: 2px solid #1E1E1E;
    border-bottom: 2px solid #1E1E1E;
}

.dMLxmX:not(:last-child) {
    border-right: 2px solid #121212;
}

@media (min-width: 768px) {
    .dMLxmX {
        padding: 22px 36px 8px;
    }

}

.dMLxmX {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 14px 24px;
}

.jKdujb {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 3px;
    color: white;
}

.hDWZWp {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 14px;
    color: #12B2B3;
}

.kEwaiX {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 2px;
}

.fPSkut {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: -0.0109598em;
    line-height: 24px;
    color: white;
}

.kIVshV {
    display: flex;
    position: relative;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 6px;
    margin-bottom: 18px;
    max-width: 138px;
}

.bdXizj {
    display: flex;
    width: 24px;
    height: 4px;
    background: #12B2B3;
}

.cUwwiO {
    display: flex;
    width: 24px;
    height: 4px;
    background: #064343;
}

@media (min-width: 768px) {
    .dMLxmX {
        padding: 22px 36px 8px;
    }
}

.dMLxmX {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 14px 24px;
    text-align: start;
}

@media (min-width: 768px) {
    .jaTiyn {
        margin: 8px 0px;
        text-align: center;
    }
}

.jaTiyn {
    margin: 8px 24px 8px;
    color: white;
}

.gECtKm {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.000490774em;
    line-height: 16px;
    color: #757575;
    text-align: start;
}

/* Common style for all screen sizes */
.jMwAxD {
    user-select: none;
    padding: 0px 24px;
    margin-bottom: 32px;
    text-align: start;
}

/* Media query for screens with a minimum width of 768px */
@media (min-width: 768px) {
    .jMwAxD {
        font-size: 48px;
        line-height: 67px;
        padding: 0px;
        margin-bottom: 16px;
    }
}

.lfFgMS1 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    letter-spacing: -0.0218097em;
    line-height: 48px;
    color: white;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    list-style-position: inside;
}


.khojTM {
    width: 100%;
    max-width: 343px;
    margin: 0px auto 56px;
}

@media (min-width: 768px) {
    .khojTM {
        max-width: 608px;
        margin: 0px auto;
        display: grid;
        flex-direction: row;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 24px;
    }

}


.jYjMog {
    color: white;
    max-width: 376px;
    margin: 0px auto 16px;
    padding: 0px 24px;
}

@media (min-width: 1366px) {
    .jYjMog {
        margin-bottom: 40px;
        width: 648px;
        max-width: 648px;
    }
}

@media (min-width: 768px) {
    .jYjMog {
        width: 608px;
        max-width: 608px;
        padding: 0px;
        font-size: 48px;
        line-height: 67px;
    }
}

.jYjMog b {
    color: #12B2B3;
}


.fJgeSu {
    background-color: #393939;
    /* padding: 16px; */
    padding: 24px 40px;
    width: 100%;
    position: relative;
    margin-bottom: 24px;
    transition: opacity 0.3s ease-in 0s;
    opacity: 1;
}

@media (min-width: 1366px) {
    .lfFgMS1 {
        font-size: 48px;
        line-height: 67px;
    }
}


@media (min-width: 768px) {
    .fJgeSu {
        padding: 24px 40px;
        margin: 0px;
        grid-area: 1 / 1 / 2 / 3;
    }
}



.cMXlHu {
    padding: 0px;
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
    height: 24px;
    width: 24px;
}

@media (min-width: 768px) {
    .cMXlHu {
        top: 24px;
        right: 40px;
        color: transparent;
    }
}


.guONjc {
    font-weight: 700;
    margin-bottom: 10px;
    transition: opacity 0.3s ease-in 0.2s;
    opacity: 1;
    text-align: start;
}


.eMkHFL {
    font-weight: 700;
    margin-bottom: 16px;
    transition: opacity 0.3s ease-in 0.4s;
    opacity: 1;
    text-align: start;
}


.evNftI {
    font-size: 28px;
    line-height: 40px;
}

.rtJBA {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: -0.0183197em;
    line-height: 30px;
    color: white;
}

@media (min-width: 1366px) {
    .rtJBA {
        font-size: 28px;
        line-height: 40px;
    }

}



.ktbeEO {
    transition: opacity 0.3s ease-in 0.5s;
    opacity: 1;
}

.cdcujb {
    background: linear-gradient(90deg, rgb(74, 89, 230) 0%, rgb(86, 158, 242) 21.35%, rgb(117, 198, 179) 39.58%, rgb(191, 228, 139) 55.21%, rgb(255, 229, 147) 68.75%, rgb(250, 133, 87) 84.93%, rgb(236, 62, 79) 100%);
    height: 8px;
    position: relative;
    margin-bottom: 6px;
}

.cdcujb::after {
    content: "";
    position: absolute;
    top: 100%;
    transform: translateY(-50%) translateX(-50%);
    display: block;
    height: 14px;
    width: 14px;
    background: url('../images/triangle.svg') center center no-repeat;
    transition: opacity 0.3s ease-in 0.5s, left 0.3s ease-in 0.6s;
    opacity: 1;
    left: 98%;
}

.fgwdrk {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-bottom: 18px;
}

.kNHdTk:first-child {
    text-align: left;
}


.kNHdTk {
    font-size: 14px;
    line-height: 22px;
    width: calc(50%);
    color: white;
}

.eaRkvO {
    transition: opacity 0.3s ease-in 0.7s;
    opacity: 1;
    text-align: start;
}

.cxWgCs {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #12B2B3;
}

.ebehgr {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.002em;
    color: white;
}


.cooBle {
    background-color: #393939;
    padding: 30px 16px 16px;
    width: 100%;
    position: relative;
    height: 200px;
    margin-bottom: 24px;
    transition: opacity 0.3s ease-in 0s;
    opacity: 1;
}


@media (min-width: 768px) {
    .cooBle {
        margin: 0px;
        grid-area: 2 / 1 / 3 / 2;
    }

}

@media (min-width: 768px) {
    .cooBle {
        margin: 0px;
        grid-area: 2 / 1 / 3 / 2;
    }
}


.cukhrM {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.002em;
    color: #12B2B3;
    border: 1px solid #12B2B3;
    padding: 4px 8px;
}

.hXfmOV {
    display: flex;
    flex-flow: row;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.lFpGz {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-basis: auto;
    width: 56px;
    height: 56px;
    font-size: 34px;
    line-height: 48px;
    background: #393939;
    border: 2px solid #8D8D8D;
    margin-right: 16px;
}

.bWebSz {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: white;
}

.elndyf {
    font-weight: 700;
    transition: opacity 0.3s ease-in 0.2s;
    opacity: 1;
}

.eKxreK {
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: white;
    transition: opacity 0.3s ease-in 0.4s;
    opacity: 1;
}

.bmGVMM {
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    flex-flow: column;
    width: 100%;
}

.chnYuq {
    width: 100%;
    height: 8px;
    background: linear-gradient(270deg, #12B2B3 0%, rgba(255, 68, 0, 0.07) 100%);
    position: relative;
}

.chnYuq::after {
    opacity: 1;
    left: 22.78%;
}


.chnYuq::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 34px;
    height: 18px;
    background: #12B2B3;
    border: 2px solid #1e1e1e;
    box-shadow: 0 2px 2px #1e1e1e;
    border-radius: 30px;
    opacity: 0;
    left: 0px;
    transition: opacity 0.3s ease-in 0.5s, left 1s ease-in 0.5s;
}

.cBkZfO {
    font-size: 14px;
    line-height: 22px;
    color: white;
}


.bzNGGh {
    background-color: #393939;
    padding: 30px 16px 16px;
    width: 100%;
    position: relative;
    height: 200px;
    transition: opacity 0.3s ease-in 0s;
    opacity: 1;
}


@media (min-width: 768px) {
    .bzNGGh {
        margin: 0px;
        grid-area: 2 / 2 / 3 / 3;
    }

}



.hXfmOV {
    display: flex;
    flex-flow: row;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.gEYMIQ {
    font-weight: 700;
    transition: opacity 0.3s ease-in 0.2s;
    opacity: 1;
}

.fxCnbD {
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: white;
    transition: opacity 0.3s ease-in 0.4s;
    opacity: 1;
}

.hSzqML {
    position: relative;
    height: 32px;
    margin-top: -2px;
}

.fjveaj {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}



.gYIXoe {
    width: 22px;
    height: 32px;
    background: url('../images/glass_full.svg') center center no-repeat;
}

.gTaTFO {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.kfuDV {
    width: 22px;
    height: 32px;
    background: url('../images//glass_full.svg') center center no-repeat;
    transition: opacity 0.2s ease-in 0.95s;
    opacity: 1;
}

.ePrnDN {
    width: 22px;
    height: 32px;
    background: url('../images/glass_full.svg') center center no-repeat;
    transition: opacity 0.2s ease-in 1.05s;
    opacity: 1;
}

.SbAMc {
    width: 22px;
    height: 32px;
    background: url('../images/glass_full.svg') center center no-repeat;
    transition: opacity 0.2s ease-in 1.15s;
    opacity: 1;
}

.gKtDBn {
    width: 22px;
    height: 32px;
    background: url('../images/glass_full.svg') center center no-repeat;
    transition: opacity 0.2s ease-in 1.25s;
    opacity: 1;
}

.hRHTDK {
    width: 22px;
    height: 32px;
    /* background: url('../images/glass_full.svg') center center no-repeat; */
    transition: opacity 0.2s ease-in 1.35s;
    opacity: 1;
}

.jSvNYf {
    width: 22px;
    height: 32px;
    background: url('../images/glass_full.svg') center center no-repeat;
    transition: opacity 0.2s ease-in 1.45s;
    opacity: 1;
}

.gCGjQg {
    width: 22px;
    height: 32px;
    background: url('../images//glass_empty.svg') center center no-repeat;
    transition: opacity 0.2s ease-in 1.55s;
    opacity: 1;
}

.bfCDgv {
    width: 22px;
    height: 32px;
    background: url('../images/glass_full.svg') center center no-repeat;
    transition: opacity 0.2s ease-in 1.65s;
    opacity: 1;
}

.hAXDHU {
    height: 25.0909px;
    min-width: 24px;
    width: 24px;
}


.cDmkPL {
    position: relative;
    z-index: 1;
    margin: 0px auto 40px;
    max-width: 376px;
    padding: 0px 24px;
    width: 100%;
}

@media (min-width: 768px) {
    .cDmkPL {
        max-width: 608px;
        width: 608px;
        padding: 0px;
    }
}


.dtPVxR {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #393939;
    margin-bottom: 16px;
    padding: 0px 0px 16px;
}

@media (min-width: 768px) {
    .dtPVxR {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }
}


.bWxWQq {
    overflow: hidden;
    display: flex;
    padding: 8px;
    max-height: 96px;
    background: #1e1e1e;
}

@media (min-width: 768px) {
    .bWxWQq:not(:last-child) {
        margin: 0px;
    }
}

.bWxWQq:not(:last-child) {
    margin-bottom: 16px;
}

.iNLxjs {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    left: 8px;
    top: 8px;
    font-size: 34px;
    line-height: 48px;
    border: 1px solid #393939;
    background: #2a2a2a;
}

.itfSmn {
    text-align: start;
    margin-left: 16px;
    width: calc(100% - 48px);
}

.cvqcMd {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #dadada;
}

.eREnij {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
}

.cDpolK1 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.0143007em;
    line-height: 26px;
    color: white;
    width: 100%;
}

.UnrjU {
    display: flex;
    padding: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    background: #1e1e1e;
}

.hSlFBB {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #12B2B3;
}


.ehulve {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.dEzpXA {
    margin: 0px auto 36px;
    max-width: 376px;
    padding: 0px 24px;
    width: 100%;
}

@media (min-width: 768px) {
    .dEzpXA {
        max-width: 608px;
        width: 608px;
        padding: 0px;
        margin-bottom: 80px;
    }
}

.dkXEtm {
    margin: 0px auto 12px;
    width: 328px;
    font-weight: 700;
    font-size: 34px;
    line-height: 48px;
    padding-top: 0px;
}

@media (min-width: 768px) {
    .dkXEtm {
        padding-top: 0px;
        width: 608px;
        font-size: 48px;
        line-height: 67px;
        text-align: center;
    }
}

.jiwYsD {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 16px;
    text-align: start;
}



.dXEqtV {
    display: flex;
    flex-direction: column;
    padding: 0px;
}

@media (min-width: 768px) {
    .dXEqtV {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}


.cMVbfJ {
    display: flex;
    margin-bottom: 16px;
}

.jTWwKW {
    margin-right: 8px;
}


.iQMCza {
    height: 24px;
    min-width: 24px;
    width: 24px;
}





.eMmwoO {
    position: relative;
    width: 100%;
    margin-bottom: 68px;
}

.cwJHo {
    position: sticky;
    z-index: -1;
    top: 130px;
}

.eKcvtO {
    display: flex;
    margin: 0px auto;
    max-width: 328px;
}

@media (min-width: 768px) {
    .eKcvtO {
        max-width: 608px;
    }
}

.kgsOUU {
    position: relative;
    z-index: 1;
    padding-left: 0px;
    list-style: none;
}


.dXYGbD {
    display: flex;
    flex-flow: column;
    -webkit-box-pack: center;
    justify-content: center;
    min-height: 416px;
}

@media (min-width: 768px) {
    .dXYGbD {
        min-height: 452px;
    }
}

.bShIVs {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: white;
    text-align: start;
}

.bShIVs:not(:last-of-type) {
    margin-bottom: 64px;
}

@media (min-width: 768px) {
    .bShIVs {
        font-size: 22px;
        line-height: 30px;
    }
}

.AcJuU {
    position: sticky;
    top: 130px;
    flex-shrink: 0;
    margin-top: 32px;
    margin-left: 8px;
    width: 144px;
    height: 356px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

@media (min-width: 768px) {
    .AcJuU {
        margin-top: 24px;
        margin-left: 32px;
        width: 177px;
        height: 440px;
    }
}

.hsuApy {
    position: absolute;
    top: -2px;
    left: 3px;
    width: 96%;
    height: auto;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0ms, opacity 400ms linear;
}

@media (min-width: 768px) {
    .hsuApy {
        left: 4px;
    }
}


.gUMxIB {
    position: absolute;
    top: -2px;
    left: 3px;
    width: 96%;
    height: auto;
    transition: visibility 0s linear 400ms, opacity 400ms linear 0s;
    visibility: hidden;
    opacity: 0;
}

@media (min-width: 768px) {
    .gUMxIB {
        left: 4px;
    }
}

.gHIWvh {
    display: block;
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
    margin-top: 46px;
}

@media (min-width: 768px) {
    .gHIWvh {
        width: 56px;
        height: 56px;
        margin-bottom: 24px;
    }
}


.PrJOx {
    background: radial-gradient(87.54% 59.7% at 50.13% 54.1%, rgba(31, 147, 255, 0.275) 0%, rgba(30, 30, 30, 0) 100%);
    padding: 32px 0px;
}

@media (min-width: 768px) {
    .PrJOx {
        background: radial-gradient(48.78% 64.02% at 50.13% 54.1%, rgba(31, 147, 255, 0.275) 0%, rgba(30, 30, 30, 0) 100%);
        padding: 64px 0px;
    }
}

.hhGCCz {
    margin: 0px auto;
    max-width: 328px;
}

@media (min-width: 768px) {
    .hhGCCz {
        max-width: 440px;
    }
}

.jHmcQA {
    text-align: center;
    margin-bottom: 16px;
}

@media (min-width: 768px) {
    .jHmcQA {
        font-size: 34px;
        line-height: 48px;
    }
}

.hzIbjB {
    text-align: start;
    color: white;
}

.cATWjc {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.cATWjc:not(:last-child) {
    margin-bottom: 16px;
}


@media (min-width: 768px) {
    .cATWjc:not(:last-child) {
        margin-bottom: 8px;
    }
}


.eGelwk {
    margin-right: 16px;
}

.gZifrk {
    font-weight: 400;
}

.eBRzvd {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.0109598em;
    line-height: 28px;
    color: var(--color-black0);
}

.idHQzB {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    z-index: 2;
    padding: 16px;
    background: #1e1e1e;
    position: sticky;
    bottom: 0px;
}

@media (min-width: 768px) {
    .idHQzB {
        padding: 14px 20px 20px;
        margin: 0px 0px 24px;
        background: linear-gradient(rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0) 0.01%, rgb(18, 18, 18) 100%);
    }

}


.idHQzB button {
    width: 100%;
}

@media (min-width: 768px) {
    .idHQzB button {
        min-width: 312px;
        width: auto;
    }
}


button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
    cursor: pointer;
}

.fdiIEf {
    display: inline-flex;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-box-align: center;
    align-items: center;
    background:#12B2B3;
}

.fdiIEf>p {
    padding-right: 32px;
}


.fdiIEf>p {
    width: 100%;
    text-align: left;
}

.kbMuAn {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.0143007em;
    line-height: 24px;
    color: white;
}

.eITCMH {
    height: 23.04px;
    min-width: 24px;
    width: 24px;
}

.dNUxgu {
    background-color: #171717;
    margin: 0px auto 60px;
    width: 100%;
    position: relative;
    overflow: hidden;
}


@media (min-width: 768px) {
    .dNUxgu {
        width: calc(100% - 160px);
    }
}


@media (min-width: 1366px) {
    .dNUxgu {
        width: calc(100% - 240px);
        max-width: 1126px;
    }
}

.kecdPy {
    display: flex;
    flex-direction: column;
    padding: 80px 24px 0px;
}

@media (min-width: 768px) {
    .kecdPy {
        padding: 80px 80px 0px;
    }
}

@media (min-width: 1366px) {
    .kecdPy {
        flex-direction: row;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 32px 40px 0px;
    }

}

.iMDOAZ {
    position: relative;
}


@media (min-width: 1366px) {
    .iMDOAZ {
        display: flex;
        flex-flow: wrap;
        flex: 1 0 424px;
        margin-right: 42px;
    }
}


@media (min-width: 1366px) {
    .bJpRLk {
        overflow: visible;
        align-self: flex-end;
    }
}



.hLHEEU {
    margin: 0px auto;
    width: 170px;
    height: 248px;
    overflow: hidden;
}

@media (min-width: 768px) {
    .hLHEEU {
        height: 292px;
        width: 200px;
    }
}

@media (min-width: 1366px) {
    .hLHEEU {
        overflow: visible;
        align-self: flex-end;
        height: 620px;
        width: 450px;
    }
}


.jjFSPS {
    position: relative;
    top: -16px;
}

@media (min-width: 1366px) {
    .jjFSPS {
        position: absolute;
        top: 0px;
        left: 0px;
    }
}


.kCrxig {
    text-align: center;
    margin-bottom: 8px;
}

.fyblzY {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.0109598em;
    line-height: 24px;
    text-transform: none;
    color: white;
}

.dRVBGu {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}


@media (min-width: 768px) {
    .dRVBGu {
        -webkit-box-pack: center;
        justify-content: center;
    }
}


.cdNInI {
    background-color: #2a2a2a;
    white-space: nowrap;
}

.cdNInI:nth-child(1),
.cdNInI:nth-child(2) {
    visibility: visible;
    width: auto;
    opacity: 1;
}

.cdNInI:not(:last-of-type) {
    margin-right: 10px;
}

.cdNInI:nth-child(1) {
    animation-name: cQJcpl;
    animation-delay: 2s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

.cOiKxN {
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 59.9%, rgba(255, 255, 255, 0) 100%) text;
    -webkit-text-fill-color: transparent;
    padding: 4px 0px 4px 8px;
}


.lbtHQX {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.000490774em;
    line-height: 16px;
    text-transform: none;
}

.dkUPYA {
    padding: 4px 8px 4px 0px;
}

.lbtHQX {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.000490774em;
    line-height: 16px;
    text-transform: none;
}


.kNrDXr {
    position: relative;
    flex: 1 1 auto;
    margin: 0px 0px 16px;
    width: 100%;
}

@media (min-width: 768px) {
    .kNrDXr {
        margin: 0px 0px 16px;
    }
}

.WHgXf {
    position: relative;
    z-index: 1;
    animation: 0.4s ease 0s 1 normal none running iSxEff;
}

.hWoZIk {
    background-color: #1e1e1e;
    box-shadow: rgba(0, 0, 0, 0.85) 0px 4px 14px;
}

.hMLZfk {
    padding: 16px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: column;
}

.DjmCs {
    align-self: flex-end;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
    cursor: pointer;
}

.euqBEn {
    height: 16px;
    min-width: 16px;
    width: 16px;
}

.izdUpT {
    display: flex;
    flex-direction: row;
}

.jTOIdQ {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 8px;
    height: 48px;
    width: 50%;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.jTOIdQ.active {
    background: #2a2a2a;
    border-bottom: 2px solid #12B2B3;
    cursor: default;
}

.efGXPp {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
}

.iJLZKX {
    display: flex;
    flex-direction: column-reverse;
    padding: 16px;
    width: 100%;
}

@media (min-width: 768px) {
    .iJLZKX {
        padding: 32px;
    }
}

.jIENgw {
    margin: 0px auto;
    max-width: 370px;
    width: 100%;
}

.heCyFg {
    margin-bottom: 16px;
}

.bqxbtN {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
}

.fPSkut {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: -0.0109598em;
    line-height: 24px;
    color: white;
}

.dLPCbN {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.002em;
    text-align: center;
    color: #b3b3b3;
    margin-bottom: 40px;
}

del {
    text-decoration: line-through;
}

.eyQnWe {
    position: relative;
}

.cXAhQC {
    margin: 0px auto 24px;
    width: 100%;
    padding-top: 20px;
}

.MHqqa {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    min-height: 45px;
    flex-flow: wrap;
    width: 100%;
    z-index: 1;
}

.iMwACa {
    display: flex;
    max-width: 470px;
    width: 100%;
}

.hWoZIk {
    background-color: #1e1e1e;
    box-shadow: rgba(0, 0, 0, 0.85) 0px 4px 14px;
}

.hMLZfk {
    padding: 16px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: column;
}

.DjmCs {
    align-self: flex-end;
}

.izdUpT {
    display: flex;
    flex-direction: row;
}

.jTOIdQ {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 8px;
    height: 48px;
    width: 50%;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.jTOIdQ.active {
    background: var(--color-black70);
    border-bottom: 2px solid var(--color-primary40);
    cursor: default;
}

.efGXPp {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
}

#zoid-paypal-buttons-uid_96b3f317f8_mdi6mtq6mdy {
    position: relative;
    display: inline-block;
    width: 100%;
    min-height: 25px;
    min-width: 150px;
    font-size: 0;
}

#zoid-paypal-buttons-uid_96b3f317f8_mdi6mtq6mdy>iframe.visible {
    opacity: 1;
}

#zoid-paypal-buttons-uid_96b3f317f8_mdi6mtq6mdy>iframe.component-frame {
    z-index: 100;
}

#zoid-paypal-buttons-uid_96b3f317f8_mdi6mtq6mdy>iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#zoid-paypal-buttons-uid_96b3f317f8_mdi6mtq6mdy>.smart-menu {
    position: absolute;
    z-index: 300;
    top: 0;
    left: 0;
    width: 100%;
}

#zoid-paypal-buttons-uid_96b3f317f8_mdi6mtq6mdy {
    position: relative;
    display: inline-block;
    width: 100%;
    min-height: 25px;
    min-width: 150px;
    font-size: 0;
}

iframe {
    overflow-clip-margin: content-box !important;
    overflow: clip !important;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
}


.kDGfPZ {
    position: relative;
}


.gJLJJI {
    margin: 0px auto 16px;
    min-height: 300px;
}

@media (min-width: 768px) {
    .gJLJJI {
        min-width: 360px;
    }
}

.gJLJJI>div>div {
    align-items: flex-start;
    display: flex;
    width: 100%;
}


.enKCCT {
    margin: 24px 0px;
}


.gpay-card-info-container {
    padding: 0;
    position: relative;
    min-width: 240px;
    height: 40px;
    min-height: 40px;
    border-radius: 4px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 1px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    border: 0px;
}


.gpay-card-info-container-fill,
.gpay-card-info-container-fill>.gpay-card-info-container {
    width: 100%;
    height: inherit;
}

.gpay-card-info-container.white,
.gpay-card-info-animation-container.white {
    background-color: #fff;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
    cursor: pointer;
}

.gpay-card-info-container.white,
.gpay-card-info-animation-container.white {
    background-color: #fff;
}

.gpay-card-info-animation-container-fade-out {
    animation-fill-mode: forwards;
    animation-duration: 0.6s;
    animation-name: gpayPlaceHolderFadeOut;
}

.gpay-card-info-placeholder-container {
    display: flex;
    width: 100%;
    height: 100%;
    margin: auto;
    justify-content: center;
}

.gpay-card-info-container-fill .gpay-card-info-placeholder-container,
.gpay-card-info-container-fill-new-style .gpay-card-info-placeholder-container {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 3px;
    box-sizing: border-box;
    overflow: hidden;
}


.gpay-card-info-animation-gpay-logo {
    margin: 13px 7px 0px 39px;
    background-origin: content-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 17px;
    max-height: 17px;
    max-width: 41px;
    min-width: 41px;
}

.gpay-card-info-container-fill .gpay-card-info-animation-gpay-logo {
    background-position: right;
    margin: 0 0 0 0;
    max-width: none;
    width: 25%;
    height: inherit;
    max-height: 50%;
}


.gpay-card-info-animation-gpay-logo.white {
    background-image: url(https://www.gstatic.com/instantbuy/svg/light_gpay.svg);
}

.gpay-card-info-container-fill .gpay-card-info-placeholder-svg-container,
.gpay-card-info-container-fill-new-style .gpay-card-info-placeholder-svg-container {
    position: relative;
    width: 60%;
    height: inherit;
    max-height: 80%;
    margin-right: -20%;
}

.gpay-card-info-animated-progress-bar-container {
    display: flex;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
}

.gpay-card-info-animated-progress-bar {
    border-radius: 4px 4px 0px 0px;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: gpayProgressFill;
    animation-timing-function: cubic-bezier(0.97, 0.33, 1, 1);
    background: #caccce;
    width: 100%;
    height: 3px;
    max-height: 3px;
}

.gpay-card-info-animated-progress-bar-indicator {
    border-radius: 4px 4px 0px 0px;
    max-width: 20px;
    min-width: 20px;
    height: 3px;
    max-height: 3px;
    background: linear-gradient(to right, #caccce 30%, #acaeaf 60%);
    animation-delay: 0.5s;
    animation-duration: 1.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: gpayPlaceHolderShimmer;
}


.gpay-card-info-iframe {
    border: 0;
    display: block;
    height: 100%;
    margin: auto;
    max-width: 100%;
    width: 240px;
}

.gpay-card-info-container-fill .gpay-card-info-iframe,
.gpay-card-info-container-fill-new-style .gpay-card-info-iframe {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}

.gpay-card-info-iframe-fade-in {
    animation-fill-mode: forwards;
    animation-duration: 0.6s;
    animation-name: gpayIframeFadeIn;
}

.cnlcwx {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
}

.icbhsR {
    display: flex;
    flex-flow: column;
    position: relative;
    width: 100%;
}

.fOhYAU {
    margin: 0px auto;
    min-height: 310px;
    width: 100%;
    max-width: 400px;
}


.fOhYAU iframe {
    border: 0px;
    min-height: 310px;
    min-width: 100%;
    width: 100%;
}

.eTaYcj {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 100%;
}

.cEOkUp {
    position: relative;
    text-align: center;
    margin-bottom: 24px;
}

@media (min-width: 1366px) {
    .cEOkUp {
        text-align: left;
    }
}


.xIfj {
    display: flex;
    flex-direction: column;
}

.kfkPfX {
    display: flex;
    flex-flow: column;
    margin-bottom: 16px;
    overflow: hidden;
    width: 100%;
}

@media (min-width: 768px) {
    .kfkPfX {
        margin-bottom: 16px;
    }
}

.cwyBUq {
    -webkit-box-align: center;
    align-items: center;
    background: #1e1e1e;
    overflow: hidden;
    padding: 24px 0px 24px 14px;
    position: relative;
}

.kWqhrX {
    -webkit-box-align: center;
    align-items: center;
    border: 2px solid #393939;
    display: inline-flex;
    background-color: #1e1e1e;
    cursor: pointer;
}

.eTaYcj {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 100%;
}

.dNuCWe {
    display: flex;
    flex-flow: column wrap;
    padding: 0px 8px;
}

.fyblzY {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.0109598em;
    line-height: 24px;
    text-transform: none;
}

.bTYyBy {
    font-size: 14px;
    width: 100%;
    color: #b3b3b3;
}

.bTYyBy .division {
    font-size: 14px;
}

.lhlHag {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.00622354em;
    line-height: 22px;
    text-transform: none;
}

.hrtFdt {
    margin-right: 18px;
}

@media (min-width: 768px) {
    .hrtFdt {
        margin-right: 40px;
    }
}

.fikCKZ {
    display: flex;
    font-size: 22px;
    line-height: 30px;
    flex-wrap: nowrap;
}

.fKEBTI {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.0143007em;
    line-height: 26px;
    text-transform: none;
}

.hIowsA {
    color: #b3b3b3;
    display: block;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
}

.lbtHQX {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.000490774em;
    line-height: 16px;
    text-transform: none;
}


.kHAZvO {
    -webkit-box-align: center;
    align-items: center;
    border: 2px solid #12B2B3;
    display: inline-flex;
    background-color: #1e1e1e;
    cursor: pointer;
}

.ebnUab {
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    z-index: 1;
    color: white;
    background-color: #12B2B3;
    display: block;
    position: relative;
    top: -2px;
    padding: 2px 0px;
    width: 100%;
}


.gtUsHR {
    display: flex;
    gap: 11px;
    -webkit-box-pack: center;
    justify-content: center;
}

.hwvors {
    margin-top: 10px;
}

.dEecnr {
    font-size: 12px;
    color: #6a6a6a;
    margin-top: 4px;
}

.hFLdo p {
    font-size: 12px;
    line-height: 16px;
    color: #8d8d8d;
    justify-content: center;
    text-align: start;
}

.hFLdo a {
    color: #8d8d8d;
    border-bottom: 1px solid #8d8d8d;
    transition: all 0.2s ease 0s;
}

.hFLdo:last-child {
    padding-bottom: 0px;
}

@media (min-width: 1366px) {
    .hFLdo:last-child {
        padding-bottom: 40px;
    }
}

@media (min-width: 768px) {
    .hFLdo:last-child {
        padding-bottom: 24px;
    }
}


.iobIND {
    background-color: var(--color-black80);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px;
    border: 1px solid rgb(57, 57, 57);
    margin: 0px auto 40px;
    padding: 16px;
    position: relative;
    width: calc(100% - 48px);
}


@media (min-width: 1366px) {
    .iobIND {
        margin-bottom: 80px;
        max-width: 1126px;
        padding: 64px 120px;
        width: calc(100% - 240px);
    }
}

@media (min-width: 768px) {
    .iobIND {
        padding: 24px 48px 32px;
        width: calc(100% - 160px);
    }
}


.icgHWB {
    display: flex;
    align-items: flex-end;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 24px;
}


@media (min-width: 1366px) {
    .icgHWB {
        max-width: 590px;
        margin-bottom: 32px;
    }
}


.ktapds {
    flex: 0 0 147px;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: white;
}

@media (min-width: 1366px) {
    .ktapds {
        flex: 0 0 590px;
    }

}

@media (min-width: 768px) {
    .ktapds {
        flex: 0 0 380px;
        font-size: 28px;
        line-height: 40px;
    }
}

.kHOQOr {
    position: relative;
    width: 124px;
    height: 124px;
}

@media (min-width: 1366px) {
    .kHOQOr {
        position: absolute;
        top: 50%;
        right: 160px;
        transform: translateY(-50%);
        width: 192px;
        height: 192px;
    }
}


@media (min-width: 1366px) {
    .guXZjB {
        max-width: 590px;
    }
}

.guXZjB {
    text-align: start;
}

.eODAWk:first-child {
    margin-bottom: 16px;
}

.eODAWk{
    width: 100%;
}



.juFqh {
    background: radial-gradient(64.53% 62.88% at 50.13% 45.92%, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgb(35, 35, 35), rgb(35, 35, 35));
    padding: 40px 16px;
    text-align: center;
}

@media (min-width: 1366px) {
    .juFqh {
        padding: 64px 16px;
    }
}

.dcMtwF {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -0.0143007em;
    line-height: 26px;
    color: var(--color-black0);
}

.eHeNa-d {
    color: white;
    margin-bottom: 4px;
}

@media (min-width: 1366px) {
    .dcMtwF {
        font-size: 22px;
        line-height: 30px;
    }

}


@media (min-width: 768px) {
    .jFGiq {
        font-size: 48px;
        line-height: 67px;
    }

}

.jFGiq {
    color: white;
    margin-bottom: 4px;
}

@media (min-width: 1366px) {
    .lfFgMS {
        font-size: 48px;
        line-height: 67px;
    }
}

.fKTIxx {
    color: #b3b3b3;
    text-align: center;
}

.lbaJjy {
    left: 50%;
    overflow: hidden;
    padding-top: 24px;
    padding-bottom: 56px;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;
}

@media (min-width: 1366px) {
    .lbaJjy {
        width: 1142px;
    }
}

@media (min-width: 768px) {
    .lbaJjy {
        width: 624px;
    }
}

.dDqJit {
    padding: 56px 0px 80px;
}

@media (min-width: 1366px) {
    .dDqJit {
        padding: 48px 0px;
    }
}


.kytiAU {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-flow: column;
    margin: 0px auto;
    max-width: calc(100% - 32px);
    height: 216px;
    background: radial-gradient(60.6% 40.78% at 49.85% 44.22%, rgba(31, 147, 255, 0.275) 0%, rgba(30, 30, 30, 0) 100%);
}


@media (min-width: 1366px) {
    .kytiAU {
        max-width: 1126px;
        height: 364px;
        background: radial-gradient(34.93% 44.78% at 49.85% 44.22%, rgba(31, 147, 255, 0.275) 0%, rgba(30, 30, 30, 0) 100%);
    }
}

@media (min-width: 768px) {
    .kytiAU {
        max-width: 688px;
        height: 264px;
        background: radial-gradient(49.85% 55.78% at 49.85% 44.22%, rgba(31, 147, 255, 0.275) 0%, rgba(30, 30, 30, 0) 100%);
    }
}


.jGNoGa {
    color: #b3b3b3;
    text-align: center;
    margin-bottom: 16px;
}


.fCiHyJ {
    display: block;
    text-align: center;
    color: white;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
}

@media (min-width: 1366px) {
    .fCiHyJ {
        font-size: 28px;
        line-height: 40px;
    }
}


.cXatAT {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 72px;
    height: 72px;
    background: #2a2a2a;
    border: 1px solid #393939;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px;
    border-radius: 50px;
}


.BYXOS {
    display: flex;
    flex-direction: row;
}

.keiusE {
    padding-top: 24px;
    font-weight: 400;
    max-width: 290px;
    text-align: center;
}

.fKEBTI {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.0143007em;
    line-height: 26px;
    text-transform: none;
}


.kpEdpO {
    color: #8d8d8d;
    padding-top: 16px;
    max-width: 290px;
    text-align: center;
}

.lhlHag {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.00622354em;
    line-height: 22px;
    text-transform: none;
}

.dEecnr {
    font-size: 12px;
    color: #6a6a6a;
    margin-top: 4px;
}

.fsAiQT{
    align-items: start;
    text-align: start;
}


.lbaJjy {
    left: 50%;
    overflow: hidden;
    padding-top: 24px;
    padding-bottom: 56px;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;
}

@media (min-width: 768px){
    .lbaJjy {
        width: 624px;
    }
}


@media (min-width: 1366px)
{
    .lbaJjy {
        width: 1142px;
    }
}


.lbaJjy .ReviewsCarousel {
    position: relative;
    -webkit-box-align: center;
    align-items: center;
}

.egVRIa .ReviewsCarousel {
    position: relative;
    -webkit-box-align: center;
    align-items: center;
}

.fRcffV {
    display: flex;
    user-select: none;
    padding: 0px 8px;
}



.kJeQBI {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 8px;
    background: #121212;
}

.ctmhGW {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.erYcnL {
    margin-left: 8px;
    text-align: left;
    color:white;
}

.gECtKm {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.000490774em;
    line-height: 16px;
    color: #ffffff;
}

.hIzoLx {
    width: 16px;
    height: 16px;
}


.isjlys {
    padding: 8px;
    background: #393939;
}

.eJpQV {
    text-align: left;
}

.cDJtBv {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.eREnij,hSlFBB{
    width: calc(100% - 48px);
    color: white;
}

/* Align dots outside the carousel */
.carousel-container .custom-dot-list-style {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    margin-top: 10px;
}

.carousel-container .custom-dot-list-style li {
    display: inline-block;
    margin: 0 5px;
}