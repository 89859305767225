.dArWKI {
    margin-bottom: 16px;
    width: 100%;
}

.gxMAzA {
    align-items: flex-start;
    padding: 14px;
}

.hjicdk2 {
    -webkit-box-align: center;
    align-items: center;
    border: 2px solid transparent;
    display: inline-flex;
    background-color: rgb(33, 33, 33);
    cursor: pointer;
}

.hjicdk2.isChecked {
    border: 2px solid #12B2B3;
    /* Set the border width and color */
}

.hjicdk2:hover {
    background-color: #2c2c2c;
}

.jvNbPD {
    align-items: flex-start;
    display: flex;
    flex-flow: column;
    padding-left: 8px;
    width: 100%;
    justify-content: center;
    text-align: start;
}

.fnzkYY {
    font-weight: 600;
    line-height: 24px;
    font-size: 18px;
    color: white;
    text-align: start;
}

.dAwHbU {
    color: white;
    display: flex;
    padding-top: 8px;
    width: 100%;
}

.dezAzo {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.00622354em;
    line-height: 22px;
    color: white;
}