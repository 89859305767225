.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  z-index: 0px;
  background-color: transparent;
}

@media (max-width: 767px) {
  .toggle-switch {
    width: 70px;
  }
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #393939;
  border-radius: 20px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: white 0.2s ease;
}

.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 20px;
  transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked+.switch::before {
  transform: translateX(25px);
  background-color: white;
}

.toggle-switch input[type="checkbox"]:checked+.switch {
  background-color: #12B2B3;
}