.gcuvsh {
    background-color: #1e1e1e;
    border-top: 1px solid #393939;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: space-around;
    padding: 16px 16px 16px 16px;
    position: fixed;
    z-index: 1;
}

.hGJFMt {
    width: 100%;
}

.fdiIEf {
    display: inline-flex;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 0px 16px;
    -webkit-box-align: center;
    align-items: center;
    background: #12B2B3;
}


.fdiIEf>p {
    width: 100%;
    text-align: left;
}

.kbMuAn {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.0143007em;
    line-height: 24px;
    color: white;
}