.container-choose-program {
    background: linear-gradient(rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.95) 65.1%, rgb(18, 18, 18) 100%);
    width: 100%;
    height: 100%;
}

@media (min-width: 1366px) {
    .iFeSRZ {
        margin-bottom: 92px;
    }
}

@media (min-width: 768px) {
    .iFeSRZ {
        margin-bottom: 48px;
    }
}

.iFeSRZ {
    display: flex;
    width: 100%;
    flex-flow: wrap;
    -webkit-box-pack: justify;
    justify-content: center;
    margin-bottom: 80px;
}



.kXlymQ {
    margin-top: 72px;
    padding-top: 72px;
    -webkit-box-align: stretch;
    align-items: stretch;
    padding-bottom:140px

}


@media (min-width: 1366px) {
    .kXlymQ {
        margin-top: 0px;
        padding-top: 176px;
    }
}

@media (min-width: 768px) {
    .kXlymQ {
        padding-top: 136px;
    }
}

.grid-bg2 {
    background: linear-gradient(to left, #2A2A2A, 1px, transparent 1px), linear-gradient(0deg, #2A2A2A, 1px, transparent 1px);
    background-color: black;
    background-size: 80px 80px;
    display: flex;
    flex-direction: column;
}

@media (min-width: 1366px) {
    .grid-bg2 {
        background-size: 84px 84px;
    }
}

@media (min-width: 768px) {
    .grid-bg2 {
        background-size: 79px 79px;
    }
}

@media (min-width: 1366px) {
    .grid-bg2 {
        background-size: 84px 84px;
    }
}

@media (min-width: 768px) {
    .grid-bg2 {
        background-size: 79px 79px;
    }
}

.bsVSjs {
    background: linear-gradient(rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.95) 65.1%, rgb(18, 18, 18) 100%);
    min-height: 100%;
    width: 100%;
    padding-bottom: 72px;
}

.eNykmv {
    padding: 0px 24px;
    margin: 0px auto;
}



@media (min-width: 1366px) {
    .eNykmv {
        max-width: 1184px;
    }
}


.iWNVlm {
    margin-bottom: 12px;
    text-transform: none;
    align-self: center;
}


@media (min-width: 1366px) {
    .iWNVlm {
        margin-bottom: 12px;
        max-width: 100%;
        text-align: center;
    }
}

@media (min-width: 768px) {
    .iWNVlm {
        line-height: 67px;
        margin-bottom: 24px;
    }
}

@media (min-width: 768px) {
    .iWNVlm {
        line-height: 67px;
        margin-bottom: 24px;
    }
}

.jbOtEl {
    width: 100%;
}

.lfFgMS {
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.0218097em;
    line-height: 48px;
    color: white;
    font-size: 34px;
}

.GnVCv {
    margin-bottom: 40px;
    text-transform: uppercase;
    text-align: center;
}

@media (min-width: 1366px) {
    .GnVCv {
        margin-bottom: 40px;
    }

}

@media (min-width: 768px) {
    .GnVCv {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 64px;
        text-align: center;

    }
}



.eIUMfR {
    padding-left: 8px;
    color: #B3B3B3;
    font-size: 12px;
}



.SXRCO {
    margin-bottom: 24px;
}

@media (min-width: 1366px) {
    .SXRCO {
        flex: 1 1 0%;
        -webkit-box-pack: end;
        justify-content: flex-end;
        padding: 0 48px;
    }
}

@media (min-width: 768px) {
    .SXRCO {
        width: 100%;
    }
}



.ffhNwi {
    display: flex;
    flex-flow: column;
    width: 100%;
    text-align: start;
}




.jkXXc:first-child:not(:last-child) {
    margin-bottom: 16px;
}

.jkXXc {
    font-size: 12px;
}

.hjicdk {
    align-items: center;
    cursor: pointer;
}

.bxKVqE {
    border: 0px;
    align-items: flex-start;
}

.eIUMfR {
    padding-left: 8px;
    color: #B3B3B3;
}

.bVNcHg {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.002em;
    color: #B3B3B3;
}


.dgKhQM {
    background-color: red;
    display: flex;
    padding: 4px 16px;
    align-items: flex-start;
    margin-top: 24px;
}

.dezAzo {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.00622354em;
    line-height: 22px;
    color: white;
}

.custom-checkbox-label {
    margin-bottom: 10px;
    cursor: pointer;
}

.custom-checkbox-input {
    margin-right: 10px;
    /* Adjust spacing between the checkbox and text */
    cursor: pointer;
}