.fyBvSm {
    background-color: transparent;
    border-width: 4px;
    flex-flow: wrap;
    padding: 16px 0px;
    word-break: break-word;
    text-align: start;
}

.kHAZvO1 {
    -webkit-box-align: center;
    align-items: center;
    border: 0.5px solid;
    border-color: #393939 transparent;
    display: inline-flex;
    background-color: transparent;
    cursor: pointer;
}


.cKVRhv1 {
    display: flex;
    flex-flow: wrap;
    margin: 0px auto;
    padding-bottom: 24px;
    width: 100%;
    max-width: 424px;
    justify-content: center;

}

@media (max-width: 768px) {
    .cKVRhv1 {
        padding: 0 24px;
    }
}