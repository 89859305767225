.kgPnpb {
    padding: 24px;
    z-index: 4;
}

@media (min-width: 768px) {
    .hFIkTS1 {
        padding: 24px;
        max-width: 570px;
        width: 100%;
    }
}

.hFIkTS1 {
    position: relative;
    background-color: #1a1919;
    padding: 34px 52px;
    width: 100%;
    border: 1px solid #2A2A2A;
    text-align: start;
}


.lgEKak .ModalInner {
    margin-bottom: 16px;
    word-break: break-word;
    border: 1px solid #565353;
    background: #2A2A2A;
}


@media (min-width: 768px) {
    .lgEKak .ModalInner {
        padding: auto;
    }
}

.jwPRtY {
    background: inherit;
    border: none;
    cursor: pointer;
    height: 24px;
    margin: 0px;
    outline: none;
    padding: 0px;
    position: absolute;
    right: 14px;
    top: 14px;
    width: 24px;
}

.hHfqWf {
    margin-top: -48px;
}

.hHfqWf {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: -24px;
    margin-bottom: 24px;
}

.gxFBtW {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: var(--color-black70);
    width: 48px;
    height: 48px;
    margin-right: 12px;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
}

.gWIxoW {
    font-weight: 700;
    text-transform: uppercase;
}


@media (min-width: 768px) {
    .cTeCEL {
        font-size: 30px;
        line-height: 40px;
    }
}

.cTeCEL {
    font-size: 30px;
    line-height: 28px;
    margin-bottom: 24px;
    word-break: break-word;
}

.hjicdk123 {
    -webkit-box-align: center;
    align-items: center;
    border: 2px solid transparent;
    display: inline-flex;
    background-color: var(--color-black80);
    cursor: pointer;
}

.fytPbx:not(:last-child) {
    margin-bottom: 16px;
}

.fytPbx {
    padding: 16px 72px 16px 16px;
    width: 100%;
    background: #3f3f3f;
    color: white;
    font-weight: 600;
}


.kFVZPo {
    color: #12B2B3;
}