.sticky-container {
    display: flex;
    flex-direction: row;
    z-index: 999;
    width: 100%;
}

@media (min-width: 1366px) {
    .xhIjl {
        position: fixed;
        left: 0px;
        margin-bottom: 0px;
        z-index: 2;
    }
}

.xhIjl {
    padding: 0px 0px;
    position: relative;
    margin-bottom: 24px;
    z-index: 2;
}

.csXCYg {
    width: 100%;
}

.kkiseL {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    height: 24px;
    width: 100%;
}

/* Media Query for small screens */
@media (max-width: 767px) {
    .kkiseL1 {
        max-width: 424px;
        width: 100%;
    }
}

/* Media Query for medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
    .kkiseL1 {
        max-width: 424px;
        width: 100%;
    }
}

/* Media Query for large screens */
@media (min-width: 1024px) and (max-width: 1365px) {
    .kkiseL1 {
        max-width: 424px;
        width: 100%;
    }
}

/* Media Query for extra-large screens */
@media (min-width: 1366px) {
    .kkiseL1 {
        max-width: 424px;
        width: 100%;
    }
}

.kkiseL1 {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    height: 24px;
}

.bbiyBI {
    background-color: #2A2A2A;
    height: 24px;
    margin-right: 5px;
    min-width: 24px;
    width: 24px;
    margin-left: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;

}


.cvkoXM {
    color: var(--color-black10);
}

.gECtKm {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.000490774em;
    line-height: 16px;
    color: white;
    text-align: center;
    align-self: center;
    margin-right: 24px;
}