.bSTudT {
  border: 0px;
  overflow: hidden;
  margin: 8px;
  max-width: 236px;
  height: 100%;
  max-height: 178px;
}

@media (min-width: 1366px) {

  .bSTudT:first-child,
  .bSTudT:nth-child(2) {
    margin-bottom: 0px;
  }
}

.bSTudT:first-child,
.bSTudT:nth-child(2) {
  margin-bottom: 8px;
}

@media (min-width: 1366px) {
  .bSTudT {
    width: calc(25% - 18px);
  }
}

@media (min-width: 768px) {
  .bSTudT {
    width: calc(50% - 12px);
  }
}


.hjicdk {
  -webkit-box-align: center;
  align-items: center;
  background-color: #1d1d1d;
  cursor: pointer;
}

.hjicdk:hover {
  background-color: #292929;
}

.item-image {
  height: 150px;
  width: 154px;
  object-fit: contain;
  margin-bottom: -4px;
}


.gtVJbr {
  display: block;
  text-align: center;
  padding: 2px;
  width: 100%;
  background: rgb(57, 57, 57);
}

.cEmxiN {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.0143007em;
  line-height: 24px;
  color: white;
}