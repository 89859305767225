.faq-container {
    /* padding: 24px 48px 32px; */
    width: calc(100% - 60px);
    font-family: Arial, sans-serif;
    background: radial-gradient(48.78% 64.02% at 50.13% 54.1%, rgba(31, 147, 255, 0.275) 0%, rgba(30, 30, 30, 0) 100%);
  }


@media (min-width: 1366px){
    .faq-container {
        margin-bottom: 80px;
        max-width: 1126px;
        padding: 0px 0px;
        width: calc(100% - 240px);
    }
}

  .faq-title {
    text-align: center;
    font-weight: 900;
    font-family: Arial, sans-serif;
    font-size: 40px;
    color: white;
    margin: 0px 0px 30px 0px;
  }
  
  .faq-intro {
    text-align: center;
    text-align: center;
    margin-bottom: 60px;
    font-size: 24px;
    font-weight: 400;
    font-family: Arial, sans-serif;
    color: rgb(205, 205, 205);

  }
  
  .faq-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .faq-item {
    cursor: pointer;
  }
  
  .faq-question {
    font-size: 18px;
    color: white;
    display: flex;
    align-items: center;
    margin: 0;
    text-align: start;
  }

  
  .faq-answer {
    padding: 0px 0;
    color: rgb(213, 213, 213);
    text-align: start;
  }
  
  /* Font Awesome Icons */
  .faq-icon.fa {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }
  
  @media (max-width: 600px) {
    .faq-grid {
      display: block;
    }
  
    .faq-item {
      margin-bottom: 10px;
      color: white;
      text-align: start;
      border: 1px solid transparent; /* Remove the bottom white line */
    }
  }
  