.dwlkSV {
    margin-bottom: 16px;
    width: 100%;
}

.hjicdk1 {
    -webkit-box-align: center;
    align-items: center;
    border: 2px solid transparent;
    display: inline-flex;
    background-color: white;
    cursor: pointer;
    width: 476px;
    background-color: #1e1e1e;
    outline: none;
    /* Disable default focus outline */
}



/* Media Query for small screens */
@media (max-width: 767px) {
    .hjicdk1 {
        width: 425px;
    }
}

/* Media Query for medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
    .hjicdk1 {
        width: 425px;
    }
}

/* Media Query for large screens */
@media (min-width: 1024px) and (max-width: 1365px) {
    .hjicdk1 {
        width: 476px;
    }
}

/* Media Query for extra-large screens */
@media (min-width: 1366px) {
    .hjicdk1 {
        width: 476px;
    }
}

.hjicdk1.isChecked {
    border: 2px solid #12B2B3;
    /* Set the border width and color */
}

.hjicdk1:hover {
    background-color: #2c2c2c;
}

.hjicdk1:focus {
    border: 2px solid #12B2B3;
    /* Set the border width and color */
}


.jQNPoG {
    display: none;
}

.jGXvKN {
    align-self: center;
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    flex: 1 1 0%;
    flex-flow: column;
    padding-right: 16px;
    width: 100%;
}

.kSpLAz {
    margin-left: auto;
    margin-top: -16px;
    margin-right: -16px;
}


.ghbokT {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: -0.0183197em;
    line-height: 30px;
    color: white;
    text-align: left;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.item-image1 {
    inset: 0px;
    padding: 0px;
    border: none;
    margin: auto;
    -webkit-box-align: center;
    border: 2px solid transparent;
    display: inline-flex;
    cursor: pointer;
    width: 140px;
    height: 330px;
    margin-bottom: -180px;
}

.item-image2 {
    inset: 0px;
    padding: 0px;
    border: none;
    margin: auto;
    -webkit-box-align: center;
    border: 2px solid transparent;
    display: inline-flex;
    cursor: pointer;
    margin-bottom: -20px;
}

/* Add these styles to customize the checkbox */
.checkbox-title {
    display: flex;
    align-items: center;
}

.checkbox-input {
    margin-right: 8px;
    /* Adjust the spacing between the checkbox and the title */
}