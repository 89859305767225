.QwIiP {
    align-items: flex-start;
    display: flex;
    flex-flow: wrap;
    background-color: #0056b7;
    padding: 16px;
    max-width: 424px;
    width: 100%;
}

.exXzgm {
    padding-left: 8px;
    font-size: 18px;
    font-weight: 700;
    margin-top: 5px;
    width: calc(100% - 24px);    
}

.cDpolK {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -0.0143007em;
    line-height: 26px;
    color: white;
    text-align: start;
}

.sc-90d65e9d-1+.sc-90d65e9d-2 {
    padding: 0px;
    width: 100%;
}

.iWSHCx {
    padding-left: 8px;
    width: calc(100% - 24px);
}

.dezAzo1 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.00622354em;
    line-height: 22px;
    color: white;
    text-align: start;
}