/* CustomDot.css */
.custom-dot button {
    background-color: #000000; /* Light grey for non-active dots */
    border: 1px solid #d3d3d3; /* Optional: border color */
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    cursor: pointer;
}

.custom-dot.active button {
    background-color: #ffffff; /* White color for active dot */
    border: 1px solid #ffffff; /* Optional: border color */
}

