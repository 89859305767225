/* CSS for cookie policy container */
.about-us-container {
    position: relative;
    overflow: hidden;
    background: radial-gradient(60.6% 40.78% at 49.85% 44.22%, rgba(31, 147, 255, 0.275) 0%, rgba(30, 30, 30, 0) 100%) black;
  }
  


.kJLZZt {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        padding-top: 100px;

}

.bF842f7{
    padding: 20px 0px 60px 0px;
}