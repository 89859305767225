
/* CSS for custom dialog component */

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure dialog is on top of other elements */
}

.dialog {
  position: relative; /* Ensure position context for absolute positioning */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 80%;
  width: 400px; 
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: none;
  font-size: 25px;

}

.dialog-content {
  margin-top: 10px; /* Add margin for content */
  max-height: calc(100vh - 10px); /* Subtracting the height of the close button from the viewport height */
}