.btlCS {
    -webkit-box-align: baseline;
    align-items: baseline;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.izRESW {
    color: white;
    display: flex;
    font-size: 22px;
    font-weight: bold;
    line-height: 30px;
    width: 100%;
}

.eNLagR {
    margin-top: 30px;
    width: 100%;
}

.VxtJX {
    display: flex;
    flex-flow: wrap;
    margin: 0px -8px;
}