.payment-success-message {
    width: 100%;
    height: 100%;
    background: radial-gradient(65.29% 66.81% at 31.38% 37.3%, rgba(31, 147, 255, 0.34) 0%, rgba(30, 30, 30, 0) 100%), radial-gradient(88.46% 36.76% at 55.47% 63.24%, rgba(9, 126, 162, 0.45) 0%, rgba(255, 68, 0, 0) 100%), rgb(18, 18, 18);
    display: flex;
    border-radius: 5px;
    padding: 30px;
    align-self: center;
    margin: 0 auto;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    justify-content: center;
  }
  
  .payment-success-message h2 {
    color: white;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .payment-success-message p {
    color: white;
  }

  .button-go-to-dashboard {
    width: 100%;
    max-width: 300px;
    height: 50px;
    margin-top: 40px;
    border: none;
    border-radius: 5px;
    background-color: #12B2B3;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    align-self: center;
}
  